import React, { useState } from 'react';
import { Typography } from '@mui/material';
import Button from '../Button';
import StyledBill from './Bill.styles';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow-right.svg';
import { useAppSelector } from '../../store/hooks';
import BillModal from './BillModal';

type PropType = {
  open?: boolean;
  onClose?: () => void;
  type?: string;
  onClickPayBulkSkip: () => void;
};

const Bill: React.FC<PropType> = (props) => {
  const userInfo = useAppSelector((state) => state.userSlice.user);
  const fileInfo = useAppSelector((state) => state.orderSlice.uploadFile);
  /* eslint-disable no-var */
  var currentPricePerRecord = userInfo.rowCost.toFixed(2);
  var currentDiscount = useAppSelector((state) => state.orderSlice.discount);
  var totalPriceNumber = 0;
  var totalPrice = '0.00';
  var skipTraceType = 'Owner Name / Mailing Address Skip Trace';
  /* eslint-enable no-var */
  if (
    fileInfo.fields.length === 21 &&
    fileInfo.fields[0].oldField.toLowerCase().trim() ===
      'Owner'.toLowerCase() &&
    fileInfo.fields[1].oldField.toLowerCase().trim() === 'FN'.toLowerCase() &&
    fileInfo.fields[2].oldField.toLowerCase().trim() === 'LN'.toLowerCase() &&
    fileInfo.fields[3].oldField.toLowerCase().trim() === 'OA'.toLowerCase() &&
    fileInfo.fields[4].oldField.toLowerCase().trim() === 'OC'.toLowerCase() &&
    fileInfo.fields[5].oldField.toLowerCase().trim() === 'OS'.toLowerCase() &&
    fileInfo.fields[6].oldField.toLowerCase().trim() === 'OZ'.toLowerCase() &&
    fileInfo.fields[7].oldField.toLowerCase().trim() === 'PID'.toLowerCase() &&
    fileInfo.fields[8].oldField.toLowerCase().trim() === 'SA'.toLowerCase() &&
    fileInfo.fields[9].oldField.toLowerCase().trim() === 'SC'.toLowerCase() &&
    fileInfo.fields[10].oldField.toLowerCase().trim() === 'SS'.toLowerCase() &&
    fileInfo.fields[11].oldField.toLowerCase().trim() === 'SZ'.toLowerCase() &&
    fileInfo.fields[12].oldField.toLowerCase().trim() === 'LSF'.toLowerCase() &&
    fileInfo.fields[13].oldField.toLowerCase().trim() === 'BSF'.toLowerCase() &&
    fileInfo.fields[14].oldField.toLowerCase().trim() === 'LU'.toLowerCase() &&
    fileInfo.fields[15].oldField.toLowerCase().trim() === 'ALV'.toLowerCase() &&
    fileInfo.fields[16].oldField.toLowerCase().trim() === 'AIV'.toLowerCase() &&
    fileInfo.fields[17].oldField.toLowerCase().trim() === 'AV'.toLowerCase() &&
    fileInfo.fields[18].oldField.toLowerCase().trim() === 'AY'.toLowerCase() &&
    fileInfo.fields[19].oldField.toLowerCase().trim() === 'MI'.toLowerCase() &&
    fileInfo.fields[20].oldField.toLowerCase().trim() === 'ZD'.toLowerCase()
  ) {
    currentDiscount = 0;
    totalPriceNumber = (fileInfo.records - 1) * userInfo.llcCost;
    skipTraceType = 'Business Skip Trace';
    currentPricePerRecord = userInfo.llcCost.toFixed(2);
  } else {
    totalPriceNumber =
      (fileInfo.records - 1) * userInfo.rowCost * (1 - currentDiscount);
    currentPricePerRecord = userInfo.rowCost.toFixed(2);
  }
  totalPrice = totalPriceNumber.toFixed(2);
  const discount = (currentDiscount * 100).toFixed(2);

  if (
    fileInfo.fields.length === 10 &&
    fileInfo.fields[0].oldField.toLowerCase().trim() === 'firstname' &&
    fileInfo.fields[1].oldField.toLowerCase().trim() === 'lastname' &&
    fileInfo.fields[2].oldField.toLowerCase().trim() === 'recipientaddress' &&
    fileInfo.fields[3].oldField.toLowerCase().trim() === 'recipientcity' &&
    fileInfo.fields[4].oldField.toLowerCase().trim() === 'recipientstate' &&
    fileInfo.fields[5].oldField.toLowerCase().trim() ===
      'recipientpostalcode' &&
    fileInfo.fields[6].oldField.toLowerCase().trim() === 'propertyaddress' &&
    fileInfo.fields[7].oldField.toLowerCase().trim() === 'propertycity' &&
    fileInfo.fields[8].oldField.toLowerCase().trim() === 'propertystate' &&
    fileInfo.fields[9].oldField.toLowerCase().trim() === 'propertypostalcode'
  ) {
    skipTraceType = 'Owner Name / Mailing Address Skip Trace';
  } else if (
    fileInfo.fields.length === 1 &&
    fileInfo.fields[0].oldField.toLowerCase().trim() === 'email'
  ) {
    skipTraceType = 'Email Skip Trace';
  } else if (
    fileInfo.fields.length === 2 &&
    fileInfo.fields[0].oldField.toLowerCase().trim() === 'id' &&
    fileInfo.fields[1].oldField.toLowerCase().trim() === 'phone number'
  ) {
    skipTraceType = 'Phone Skip Trace';
  } else if (
    fileInfo.fields.length === 5 &&
    fileInfo.fields[0].oldField.toLowerCase().trim() === 'id' &&
    fileInfo.fields[1].oldField.toLowerCase().trim() === 'address' &&
    fileInfo.fields[2].oldField.toLowerCase().trim() === 'city' &&
    fileInfo.fields[3].oldField.toLowerCase().trim() === 'state' &&
    fileInfo.fields[4].oldField.toLowerCase().trim() === 'zip'
  ) {
    skipTraceType = 'Property Address Skip Trace';
  }

  const sectionArray = [
    {
      title: 'Skip Trace Type:',
      info: <h2>{skipTraceType}</h2>,
    },
    {
      title: 'Price/record:',
      info: <h2>${currentPricePerRecord}</h2>,
    },
    {
      title: 'Total Records:',
      info: <h2>{fileInfo.records - 1}</h2>,
    },
    {
      title: 'Discount:',
      info: <h2>{discount}%</h2>,
    },
    {
      title: 'Total:',
      info:
        props.type === 'modal' ? (
          <Typography color="primary" sx={{ fontWeight: 'bold', fontSize: '24px' }}>
            ${totalPrice}
          </Typography>
        ) : (
          <h3 className="styled-bill__container__main-content__total-price">
            ${totalPrice}
          </h3>
        ),
    },
  ];

  const [isChecked, setIsChecked] = useState(false);

  // Function to handle checkbox change
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  if (props.type === 'modal') {
    return (
      <BillModal
        {...props}
        sectionArray={sectionArray}
        isChecked={isChecked}
        handleCheckboxChange={handleCheckboxChange}
      />
    );
  }

  return (
    <StyledBill>
      <div className="styled-bill__container">
        <div className="styled-bill__container__title">
          <h3>Billing information</h3>
          <p className="text-2">Information about the amount of payment.</p>
        </div>
        <div className="styled-bill__container__main-content">
          {sectionArray.map((item, index) => (
            <div
              key={index}
              className="styled-bill__container__main-content__section"
            >
              <p className="text-1">{item.title}</p>
              {item.info}
            </div>
          ))}
        </div>
      </div>
      <div>
        <label className="text-1">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          &nbsp; I have read hsmxs and agreed with the{' '}
          <a href="/fulfillment" target="_blank">
            Fulfillment Policy
          </a>
        </label>
      </div>
      <div>
        <Button onClick={props.onClickPayBulkSkip} disabled={!isChecked}>
          <h4>Pay Bulk Skip trace</h4>
          <ArrowRightIcon />
        </Button>
      </div>
    </StyledBill>
  );
};

export default Bill;
