import { useState } from 'react';
import { Box, Card, Button } from '@mui/material';
import DragAndDropArea from '../../components/DragAndDropArea/DragAndDropArea';
import PageTitle from '../../components/PageTitle';
import AddSkipTraceModal from '../../components/AddSkipTraceModal';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import MappingDataArea from '../../components/MappingDataArea/MappingDataArea';

const ImportListPage = () => {
  const [isOpenAddSkipTraceModal, setIsOpenAddSkipTraceModal] = useState(false);
  const [isOpenImportListModal, setIsOpenImportListModal] = useState(false);

  const handleCloseImportListModal = () => {
    setIsOpenImportListModal(false);
  };

  const onOpenAddSkipTraceModal = () => {
    setIsOpenAddSkipTraceModal(true);
  };

  const handleOpenImportListModal = () => {
    setIsOpenImportListModal(true);
  };

  const onClickCancelAddSkipTraceModal = () => {
    setIsOpenAddSkipTraceModal(false);
  };
  return (
    <Box
      sx={{
        px: '30px',
        py: '25px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
      }}
    >
      <PageTitle
        title="Import List"
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          gap: 2,
        }}
      >
        <Button
          endIcon={<PlusIcon fill="white" />}
          sx={{
            px: 4,
            py: 1.5,
            borderRadius: 2,
            textTransform: 'capitalize',
          }}
          variant="contained"
          onClick={() => onOpenAddSkipTraceModal()}
        >
          Add Single Trace
        </Button>
      </PageTitle>
      <Card sx={{ p: { xs: 2, sm: 5 }, borderRadius: 5 }}>
        <DragAndDropArea />
      </Card>
      {isOpenAddSkipTraceModal ? (
        <AddSkipTraceModal
          onSubmit={handleOpenImportListModal}
          onCloseModal={onClickCancelAddSkipTraceModal}
        />
      ) : null}
      {isOpenImportListModal ? (
        <MappingDataArea
          open={isOpenImportListModal}
          onClose={handleCloseImportListModal}
        />
      ) : null}
    </Box>
  );
};

export default ImportListPage;
