import Papa from 'papaparse';

interface ISkipTraceHeaders {
  'Owner Name / Mailing Address Skip Trace': string[];
  'Business Skip Trace': string[];
  'Property Address Skip Trace': string[];
  'Email Skip Trace': string[];
  'Phone Skip Trace': string[];
}

export const SkipTraceHeaders: ISkipTraceHeaders = {
  'Owner Name / Mailing Address Skip Trace': [
    'FirstName',
    'LastName',
    'RecipientAddress',
    'RecipientCity',
    'RecipientState',
    'RecipientPostalCode',
    'PropertyAddress',
    'PropertyCity',
    'PropertyState',
    'PropertyPostalCode',
  ],
  'Business Skip Trace': [
    'Owner',
    'FN',
    'LN',
    'OA',
    'OC',
    'OS',
    'OZ',
    'PID',
    'SA',
    'SC',
    'SS',
    'SZ',
    'LSF',
    'BSF',
    'LU',
    'ALV',
    'AIV',
    'AV',
    'AY',
    'MI',
    'ZD',
  ],
  'Property Address Skip Trace': ['ID', 'Address', 'City', 'State', 'Zip'],
  'Email Skip Trace': ['Email'],
  'Phone Skip Trace': ['Id', 'Phone Number'],
};

export function downloadTemplate(SkipTraceType: keyof ISkipTraceHeaders) {
  const headers = SkipTraceHeaders[SkipTraceType];
  if (!headers) {
    return;
  }
  const csv = Papa.unparse({
    fields: headers,
    data: [],
  });

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `${SkipTraceType}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
