/* eslint-disable */
import { Box, Grid, Card, CardActionArea } from '@mui/material';
import PageTitle from '../../components/PageTitle';

const embedUrls = [
  'https://www.loom.com/embed/1e0d138cf31740e28dae6230d00207ac?sid=2722fc73-c7b2-4d3a-bf28-d7aa9fd36d69',
  'https://www.youtube.com/embed/3HAAM0l0iZk',
  'https://www.youtube.com/embed/JJSoEo8JSnc',
];

const HowToUseSitePage = () => {
  return (
    <Box sx={{ px: '30px', py: '25px', width: '100%', display: 'flex', flexDirection: 'column', gap: 4 }}>
      <PageTitle title='How to Use Site' />
      <Card sx={{ p: { xs: 2, sm: 5 }, borderRadius: 5 }}>
        <Grid container spacing={2}>
          {embedUrls.map((url, index) => (
            <Grid item xs={12} lg={6} key={index}>
              <Card
                sx={{
                  borderRadius: '15px',
                  overflow: 'hidden',
                  height: '357px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <CardActionArea
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <Box
                    component="iframe"
                    src={url}
                    title={`Video ${index + 1}`}
                    allowFullScreen
                    sx={{
                      width: '100%',
                      height: '100%',
                      border: 'none',
                      display: 'block',
                    }}
                  />
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Card>
    </Box>
  );
};

export default HowToUseSitePage;
