import styled from 'styled-components';

export default styled.div`
  width: 100%;
  grid-template-columns: 8fr 18fr;
  grid-column-gap: 40px;
  color: ${({ theme }) => theme.mainTheme.color.grey.dark};

  padding: 35px 0 0 0;
  box-sizing: border-box;

  > :not(:last-child) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .styled-mapping-area__bill {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    &__container {
      width: 100%;
      height: 100%;
      padding: 48px;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 48px;
      border-radius: ${({ theme }) => theme.borderRadius};
      border: 1px solid ${({ theme }) => theme.mainTheme.color.grey.background};

      &__title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        row-gap: 16px;

        h3 {
          color: ${({ theme }) => theme.mainTheme.color.typography.blackText};
        }

        .text-2 {
          color: ${({ theme }) => theme.mainTheme.color.typography.greyText};
        }
      }

      &__main-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 20px;

        &__section {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 46px;
          border-bottom: 1px solid ${({ theme }) => theme.mainTheme.color.grey.background};
        }

        &__total-price {
          color: ${({ theme }) => theme.mainTheme.color.red.primary};
        }
      }
    }
  }

`;
