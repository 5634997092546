/* eslint-disable */
import React from 'react';
import { Button } from '@mui/material';
import { ReactComponent as CSVIcon } from '../../assets/icons/CSV.svg';
import GhostButton from '../GhostButton/GhostButton';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg';
import { ReactComponent as TrashBinIcon } from '../../assets/icons/trash-bin.svg';
import { downloadTemplate } from './helpers';

type PropsType = {
  onClick?: () => void;
  className?: string;
  isLoading: boolean;
  isFileUpload: boolean;
  fileInfo: { name: string; size: number };
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  deleteFile: () => void;
} & React.PropsWithChildren;

const FileUpload: React.FC<PropsType> = (props) => {
  const formatBytes = (bytes: number, decimals = 2) => {
    if (!+bytes) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
  };

  const resultSize = formatBytes(props.fileInfo.size);

  if (props.isLoading) {
    return (
      <>
        <div className="styled-drag-and-drop-area__spinner">
          <div />
          <div />
          <div />
          <div />
        </div>
        <div className="styled-drag-and-drop-area__text-area">
          <h1>Uploading {props.fileInfo.name}</h1>
          <p className="text-1">{resultSize}</p>
        </div>
      </>
    );
  }

  if (props.isFileUpload) {
    return (
      <>
        <CSVIcon className="styled-drag-and-drop-area__picture-green" />
        <div className="styled-drag-and-drop-area__text-area">
          <h1>Uploaded {props.fileInfo.name}</h1>
          <p className="text-1">{resultSize}</p>
        </div>
        <GhostButton onClick={props.deleteFile}>
          <p className="text-3">Delete</p>
          <TrashBinIcon />
        </GhostButton>
      </>
    );
  }

  const ButtonStyle = { textDecoration: 'underline', textTransform: 'capitalize' }

  return (
    <>
      <CSVIcon className="styled-drag-and-drop-area__picture" />
      <div className="styled-drag-and-drop-area__text-area">
        <h1>You don’t have any traces yet! Select .csv to Upload</h1>
        <p className="text-1">or drag and drop here</p>

        <table className='skip-table'>
          <tbody>
            <tr className='skip-table'>
              <td className='skip-table'><b>Skip Trace Type</b></td>
              <td className='skip-table'><b>.csv header</b></td>
            </tr>
            <tr className='skip-table'>
              <td className='skip-table'>Owner Name / Mailing Address Skip Trace</td>
              <td className='skip-column'>FirstName,LastName,RecipientAddress,RecipientCity,RecipientState,RecipientPostalCode,PropertyAddress,PropertyCity,PropertyState,PropertyPostalCode
                <Button sx={ButtonStyle} onClick={() => downloadTemplate('Owner Name / Mailing Address Skip Trace')}>Download Template</Button>
              </td>
            </tr>
            <tr className='skip-table'>
              <td className='skip-table'>Business Skip Trace</td>
              <td className='skip-column'>Owner,FN,LN,OA,OC,OS,OZ,PID,SA,SC,SS,SZ,LSF,BSF,LU,ALV,AIV,AV,AY,MI,ZD
                <Button sx={ButtonStyle} onClick={() => downloadTemplate('Business Skip Trace')}>Download Template</Button>
                <p>Note: Please send the full header above, but with only the fields below set, for example: <code>,,,2347 Edgemont Dr,Abilene,TX,79605,,,,,,,,,,,,,,</code></p>
                <p>OA: Owner Address</p>
                <p>OC: Owner City</p>
                <p>OS: Owner State</p>
                <p>OZ: Owner Zip</p>
              </td>
            </tr>
            <tr className='skip-table'>
              <td className='skip-table'>Property Address Skip Trace</td>
              <td className='skip-column'>ID,Address,City,State,Zip
                <Button sx={ButtonStyle} onClick={() => downloadTemplate('Property Address Skip Trace')}>Download Template</Button>
              </td>
            </tr>
            <tr className='skip-table'>
              <td className='skip-table'>Email Skip Trace</td>
              <td className='skip-column'>Email
                <Button sx={ButtonStyle} onClick={() => downloadTemplate('Email Skip Trace')}>Download Template</Button>
              </td>
            </tr>
            <tr className='skip-table'>
              <td className='skip-table'>Phone Skip Trace</td>
              <td className='skip-column'>Id,Phone Number
                <Button sx={ButtonStyle} onClick={() => downloadTemplate('Phone Skip Trace')}>Download Template</Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <GhostButton>
        <label id="label-file-upload" htmlFor="input-file-upload">
          <input
            type="file"
            id="input-file-upload"
            onChange={props.handleChange}
            accept=".csv"
          />
          <p className="text-3">Upload File</p>
          <UploadIcon />
        </label>
      </GhostButton>
    </>
  );
};

export default FileUpload;
