import { Box, Grid } from '@mui/material';
import { StatusCodes } from 'http-status-codes';
import SubscriptionCard from './components/SubscriptionCard';
import config from '../../config';
import { paymentApi } from '../../api/services/userApi';
import { useAppSelector } from '../../store/hooks';
import PageTitle from '../../components/PageTitle';

function SubscriptionPage() {
  const userSubscription = useAppSelector((state) => state.userSlice.user.subscription);
  const subscriptionPlans = Object.values(config.subscriptionPlans);
  const onSubscription = async (priceId: string) => {
    try {
      const res = await paymentApi.checkoutSubscription(
        priceId,
      );
      if (res.status === StatusCodes.OK) {
        const link = document.createElement('a');
        link.href = res.data.data?.session.url || '';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <Box
      sx={{
        py: 3,
        px: 4,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
      }}
    >
      <PageTitle title="Subscriptions" />
      <Grid container spacing={3}>
        {subscriptionPlans.map((item, index) => (
          <Grid
            item
            key={index}
            xs={12}
            lg={6}
            xl={4}
            display="flex"
            justifyContent="center"
          >
            <SubscriptionCard
              {...item}
              onClick={onSubscription}
              status={
                item.id === userSubscription?.planId
                  ? userSubscription?.status
                  : undefined
              }
              disabled={
                item.id === null ||
                (userSubscription?.planId !== null && item.id !== userSubscription?.planId)
              }
              manageSubscription={
                (userSubscription?.planId !== null && item.id === userSubscription?.planId)
              }
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default SubscriptionPage;
