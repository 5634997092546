import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import Papa from 'papaparse';
import { Modal, Box, Button } from '@mui/material';
import Input from '../Input/Input';
import { parseFile } from '../../utils/parsingFileHelper';
import { convertFileToBase64 } from '../../utils/convertFileHelper';
import { getUploadFileName } from '../../utils/getUploadFileName';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setUploadFile } from '../../store/order/orderSlice';
import ErrorBalanceModal from '../ErrorBalanceModal/ErrorBalanceModal';

interface IField {
  name: string;
  title: string;
  type: string;
  required: boolean;
}

interface ISkipTraceTypeFields {
  [type: string]: IField[][];
}

interface IFormValues {
  [key: string]: string;
}

const SkipTraceTypes = {
  mailingAddress: 'MAILING_ADDRESS_SKIP_TRACE',
  // bussiness: 'BUSSINESS_SKIP_TRACE',
  // propertyAdress: 'PROPERTY_ADDRESS_SKIP_TRACE',
  // email: 'EMAIL_SKIP_TRACE',
  // phone: 'PHONE_SKIP_TRACE'
};
const SKIP_TRACE_FIELDS: ISkipTraceTypeFields = {
  [SkipTraceTypes.mailingAddress]: [
    [
      { name: 'FirstName', title: 'First Name', type: 'text', required: true },
      { name: 'LastName', title: 'Last Name', type: 'text', required: true },
    ],
    [
      { name: 'RecipientAddress', title: 'Recipient Address', type: 'text', required: true },
      { name: 'RecipientCity', title: 'Recipient City', type: 'text', required: true },
    ],
    [
      { name: 'RecipientState', title: 'Recipient State', type: 'text', required: true },
      { name: 'RecipientPostalCode', title: 'Recipient Postal Code', type: 'number', required: true },
    ],
    [
      { name: 'PropertyAddress', title: 'Property Address', type: 'text', required: true },
      { name: 'PropertyCity', title: 'Property City', type: 'text', required: true },
    ],
    [
      { name: 'PropertyState', title: 'Property State', type: 'text', required: true },
      { name: 'PropertyPostalCode', title: 'Property Postal Code', type: 'number', required: true },
    ],
  ],
};

type PropsType = {
    onCloseModal: () => void;
    onSubmit: () => void;
};

const AddSkipTraceModal: React.FC<PropsType> = (props) => {
  const [skipTraceType, setSkipTraceType] = useState(SkipTraceTypes.mailingAddress);
  const [isOpenErrorBalanceModal, setIsOpenErrorBalanceModal] = useState(false);
  const userInfo = useAppSelector((state) => state.userSlice.user);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const calculaerCost = (fields: string[], records: number) => {
    if (
      fields.length === 21 &&
      fields[0].toLowerCase().trim() === 'Owner'.toLowerCase() &&
      fields[1].toLowerCase().trim() === 'FN'.toLowerCase() &&
      fields[2].toLowerCase().trim() === 'LN'.toLowerCase() &&
      fields[3].toLowerCase().trim() === 'OA'.toLowerCase() &&
      fields[4].toLowerCase().trim() === 'OC'.toLowerCase() &&
      fields[5].toLowerCase().trim() === 'OS'.toLowerCase() &&
      fields[6].toLowerCase().trim() === 'OZ'.toLowerCase() &&
      fields[7].toLowerCase().trim() === 'PID'.toLowerCase() &&
      fields[8].toLowerCase().trim() === 'SA'.toLowerCase() &&
      fields[9].toLowerCase().trim() === 'SC'.toLowerCase() &&
      fields[10].toLowerCase().trim() === 'SS'.toLowerCase() &&
      fields[11].toLowerCase().trim() === 'SZ'.toLowerCase() &&
      fields[12].toLowerCase().trim() === 'LSF'.toLowerCase() &&
      fields[13].toLowerCase().trim() === 'BSF'.toLowerCase() &&
      fields[14].toLowerCase().trim() === 'LU'.toLowerCase() &&
      fields[15].toLowerCase().trim() === 'ALV'.toLowerCase() &&
      fields[16].toLowerCase().trim() === 'AIV'.toLowerCase() &&
      fields[17].toLowerCase().trim() === 'AV'.toLowerCase() &&
      fields[18].toLowerCase().trim() === 'AY'.toLowerCase() &&
      fields[19].toLowerCase().trim() === 'MI'.toLowerCase() &&
      fields[20].toLowerCase().trim() === 'ZD'.toLowerCase()
    ) {
      return (records - 1) * userInfo.llcCost; // There is no discount for LLC Skip
    }
    const currentDiscount = userInfo.discount ? userInfo.discount : 0;
    return (records - 1) * userInfo.rowCost * (1 - currentDiscount);
  };

  const onClickCancelBalanceModal = () => {
    setIsOpenErrorBalanceModal(false);
    onClose();
  };

  const onCurrentClickBalanceModal = () => {
    navigate('/payments/enter-amount');
  };

  const onUploadFile = async (file: File) => {
    const { records, fields } = await parseFile(file);
    const cost = calculaerCost(fields, records);
    if (userInfo.balance < cost) {
      setIsOpenErrorBalanceModal(true);
      return false;
    }
    const convertedFile = await convertFileToBase64(file);
    dispatch(
      setUploadFile({
        name: getUploadFileName(file.name),
        csvBase64: convertedFile.split('base64,')[1],
        records,
        fields,
      }),
    );
    onClose();
    return true;
  };

  const formik = useFormik({
    initialValues: SKIP_TRACE_FIELDS[skipTraceType]
      .flat()
      .reduce<IFormValues>((acc, field) => {
        acc[field.name] = '';
        return acc;
      }, {}),
    onSubmit: async (values) => {
      try {
        const fields = Object.keys(values);
        const data = Object.values(values);
        const csv = Papa.unparse({
          fields,
          data: [data],
        });
        const csvBlob = new Blob([csv], { type: 'text/csv' });
        const csvFile = new File([csvBlob], 'single-skip-trace.csv', {
          type: 'text/csv',
        });
        const success = await onUploadFile(csvFile);
        if (success) {
          props.onSubmit();
        }
      } catch (error) {
        toast.error(error);
        onClose();
      }
    },
  });

  const onClose = () => {
    formik.resetForm();
    props.onCloseModal();
  };

  // const handleTypeChange = (type: string) => {
  //   setSkipTraceType(type);
  //   formik.setValues(
  //     SKIP_TRACE_FIELDS[type].flat().reduce<IFormValues>((acc, field) => {
  //       acc[field.name] = '';
  //       return acc;
  //     }, {}),
  //   );
  // };

  const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '833px',
    width: '100%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    maxHeight: '80vh',
    overflow: 'scroll',
    borderRadius: 3,
  };

  return (
    <Box>
      {isOpenErrorBalanceModal ? (
        <ErrorBalanceModal
          onCloseModal={onClickCancelBalanceModal}
          onClick={onCurrentClickBalanceModal}
        />
      ) : (
        <Modal open onClose={props.onCloseModal}>
          <Box sx={styles}>
          <Box>
            <h3>Skip Trace</h3>
          </Box>
          {/* <select
            onChange={(event) => handleTypeChange(event?.target.value)}
            value={skipTraceType}
          >
            {Object.entries(SkipTraceTypes).map(([key, value]) => (
              <option key={key} value={value}>
                {key}
              </option>
            ))}
          </select> */}
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'cenetr',
              justifyContent: 'center',
              textAlign: 'start',
              rowGap: 3,
            }}
          >
            {SKIP_TRACE_FIELDS[skipTraceType].map((fieldPair, index) => (
              <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'start',
                gap: 3,
              }}
              key={index}
              >
                {fieldPair.map((field) => (
                  <Input
                    key={field.name}
                    type={field.type}
                    nameInput={field.name}
                    title={field.title}
                    placeHolder={`Enter ${field.title}`}
                    onChange={formik.handleChange}
                    value={formik.values[field.name]}
                    error={formik.errors[field.name]}
                    isTouched={formik.touched[field.name]}
                    onBlur={formik.handleBlur}
                    required={field.required}
                  />
                ))}
              </Box>
            ))}
            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
              <Button sx={{ px: 2, py: 1, borderRadius: 2 }} type="button" onClick={onClose}>
                Cancel
              </Button>
              <Button type="submit" variant="contained" sx={{ px: 3, py: 1, borderRadius: 2 }}>
                Save
              </Button>
            </Box>
          </Box>
          </Box>
        </Modal>
      )}
    </Box>
  );
};

export default AddSkipTraceModal;
