import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import StyledMappingDataArea from './MappingDataArea.styles';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import orderThunk from '../../store/order/orderThunk';
import Bill from '../Bill/Bill';
import type { FieldType, UploadOrderRequestType } from '../../types/orderTypes';
import userThunk from '../../store/user/userThunk';

type PropType = {
  open?: boolean;
  onClose?: () => void;
};

const MappingDataArea: React.FC<PropType> = (props) => {
  const isGetUploadFile = useAppSelector((state) => state.orderSlice.uploadFile.name);
  const isLoading = useAppSelector((state) => state.orderSlice.addition.isLoading);
  const fileInfo = useAppSelector((state) => state.orderSlice.uploadFile);
  const fieldArray = useAppSelector((state) => state.orderSlice.uploadFile.fields);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onClickPayBulkSkip = async () => {
    try {
      const newFieldArray = fieldArray.map((item) => {
        const newItem: FieldType = {
          oldField: item.oldField,
          newField: item.oldField,
        };
        return newItem;
      });
      const fileRequest: UploadOrderRequestType = {
        file: fileInfo.csvBase64,
        fileName: fileInfo.name,
        fields: newFieldArray,
        records: fileInfo.records - 1,
      };
      await dispatch(orderThunk.uploadFile(fileRequest)).unwrap();
      navigate('/dashboard');
      await dispatch(userThunk.loginByToken()).unwrap();
      await dispatch(userThunk.getOrderCount()).unwrap();
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    if (!isGetUploadFile) {
      navigate('/import-list');
    }
  }, [isGetUploadFile, navigate]);

  if (isLoading) {
    return (
      <div className="styled-app__loader-mini" />
    );
  }

  return (
    <StyledMappingDataArea>
      <Bill type="modal" {...props} onClickPayBulkSkip={onClickPayBulkSkip} />
    </StyledMappingDataArea>
  );
};

export default MappingDataArea;
