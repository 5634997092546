import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { Button } from '@mui/material';
import userThunks from '../../../../store/user/userThunk';
import StyledSignUpPage from './SignUpPagePart2.styles';
import Input from '../../../../components/Input/Input';
import { ReactComponent as ChevronRightIcon } from '../../../../assets/icons/chevron-right.svg';
import { useAppDispatch } from '../../../../store/hooks';
import { userSchema } from '../../../../types/validationSchemes';
import PhoneInput from '../../../../components/PhoneInput/PhoneInput';
import GhostButton from '../../../../components/GhostButton/GhostButton';
import { convertFileToBase64 } from '../../../../utils/convertFileHelper';
import ChangeAvatarArea from '../../../../components/ChangeAvatarArea/ChangeAvatarArea';

const SignUpPagePart2 = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [avatarSrc, setAvatar] = useState('');

  const handleChangeAvatar = async (file: File) => {
    setAvatar(URL.createObjectURL(file));
    const avatarFile = await convertFileToBase64(file);
    formik.setFieldValue('file', avatarFile);
    formik.setFieldValue('removeAvatar', false);
  };

  const formik = useFormik({
    initialValues: { firstName: '', lastName: '', address: '', phone: '', city: '', state: '', zipCode: '', file: '', removeAvatar: false, ignoreCoupon: true },
    validationSchema: userSchema,
    isInitialValid: false,
    onSubmit: async (values) => {
      try {
        await dispatch(userThunks.changeInfoUser(values)).unwrap();
        const path = '/dashboard';
        navigate(path);
      } catch (err) {
        toast.error(err);
      }
    },
  });

  const handleNavToMainPage = () => {
    navigate('/dashboard');
  };

  const handleDeleteAvatar = () => {
    setAvatar('');
    formik.setFieldValue('file', '');
    formik.setFieldValue('removeAvatar', true);
  };

  const InputArray = [
    {
      nameInput: 'firstName',
      title: 'First Name',
      placeHolder: 'Enter your first name',
      value: formik.values.firstName,
      error: formik.errors.firstName,
      isTouched: formik.touched.firstName,
    },
    {
      nameInput: 'lastName',
      title: 'Last Name',
      placeHolder: 'Enter your last name',
      value: formik.values.lastName,
      error: formik.errors.lastName,
      isTouched: formik.touched.lastName,
    },
    {
      nameInput: 'address',
      title: 'Address',
      placeHolder: 'Enter your address',
      value: formik.values.address,
      error: formik.errors.address,
      isTouched: formik.touched.address,
    },
    {
      nameInput: 'phone',
      title: 'Phone Number (optional)',
      placeHolder: '+1 000 000 0000',
      value: formik.values.phone,
      error: formik.errors.phone,
      isTouched: formik.touched.phone,
    },
    {
      nameInput: 'city',
      title: 'City',
      placeHolder: 'City',
      value: formik.values.city,
      error: formik.errors.city,
      isTouched: formik.touched.city,
    },
  ];

  return (
    <StyledSignUpPage>
      <form onSubmit={formik.handleSubmit}>
        <div className="styled-signup-page__container">
          <GhostButton onClick={handleNavToMainPage}>
            <p className="text-3">Skip this step</p>
            <ChevronRightIcon />
          </GhostButton>

          <div className="styled-signup-page__container__part2__title-area">
            <p className="website-header">You are signed up</p>
            <p className="text-2 styled-signup-page__container__part2__title-area__description">Add more information about yourself. You can skip this step and add details later in Profile settings.</p>
          </div>

          <div className="styled-signup-page__container__part2__main-content">
            <ChangeAvatarArea
              avatarSrc={avatarSrc}
              handleChangeAvatar={handleChangeAvatar}
              handleDeleteAvatar={handleDeleteAvatar}
            />
            <div className="styled-signup-page__container__part2__main-content__inputs-area">
              {
                InputArray.map((item, index) => (
                  item.nameInput === 'phone' ? (
                    <PhoneInput
                      key={index}
                      nameInput={item.nameInput}
                      title={item.title}
                      placeHolder={item.placeHolder}
                      onChange={formik.handleChange}
                      value={item.value}
                      error={item.error}
                      onBlur={formik.handleBlur}
                    />
                  ) : (
                    <Input
                      key={index}
                      nameInput={item.nameInput}
                      title={item.title}
                      placeHolder={item.placeHolder}
                      onChange={formik.handleChange}
                      value={item.value}
                      error={item.error}
                      isTouched={item.isTouched}
                      onBlur={formik.handleBlur}
                    />
                  )
                ))
              }

              <div className="styled-signup-page__container__part2__main-content__inputs-area__last-inputs">
                <Input
                  nameInput="state"
                  title="State"
                  placeHolder="State"
                  onChange={formik.handleChange}
                  value={formik.values.state}
                  error={formik.errors.state}
                  isTouched={formik.touched.state}
                  onBlur={formik.handleBlur}
                />

                <Input
                  nameInput="zipCode"
                  title="ZIP code"
                  placeHolder="ZIP code"
                  onChange={formik.handleChange}
                  value={formik.values.zipCode}
                  error={formik.errors.zipCode}
                  isTouched={formik.touched.zipCode}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
          </div>

          <Button sx={{ borderRadius: 2, py: 2, px: 4 }} type="submit" variant="contained">
            <h4>Continue</h4>
          </Button>

        </div>
      </form>
    </StyledSignUpPage>
  );
};

export default SignUpPagePart2;
