import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  Typography,
} from '@mui/material';
import { ReactComponent as Logo } from '../../assets/icons/SkipTracing-Logo.svg';
import Avatar from '../Avatar';
import { useAppSelector } from '../../store/hooks';
import LogoutButton from '../LogoutButton';
import config from '../../config';

const Links = [
  { title: 'Pricing', path: `${config.staticAppDomain}/pricing` },
  { title: 'FAQ', path: `${config.staticAppDomain}/faq` },
  { title: 'Support', path: `${config.staticAppDomain}/support` },
];

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const user = useAppSelector((store) => store.userSlice.user);
  const navigate = useNavigate();

  const onNavigate = (path: string) => {
    navigate(path);
  };

  const toggleDrawer = (open: boolean) => {
    setDrawerOpen(open);
  };

  const linkStyle = {
    textDecoration: 'none',
    color: 'black',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
  };

  return (
    <>
      <Box display="flex" justifyContent="center">
        <AppBar
          position="static"
          color="transparent"
          elevation={0}
          sx={{ maxWidth: 1200 }}
        >
          <Toolbar sx={{ height: 100 }}>
            {isMobile ? (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Box>
                  <Logo />
                </Box>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={() => toggleDrawer(true)}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  anchor="left"
                  open={drawerOpen}
                  onClose={() => toggleDrawer(false)}
                >
                  <Box sx={{ width: 250 }}>
                    <List>
                      {Links.map((link, index) => (
                        <ListItem key={index} component="a" href={link.path}>
                          <ListItemText sx={linkStyle} color="primary" primary={link.title} />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Drawer>
              </Box>
            ) : (
              <Box
                width="100%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Logo />
                </Box>
                <Box display="flex" width="55%" justifyContent="space-evenly">
                  {Links.map((link, index) => (
                    <Typography
                      key={index}
                      sx={linkStyle}
                      component="a"
                      href={link.path}
                    >
                      {link.title}
                    </Typography>
                  ))}
                </Box>
                {
                  user?.userId ? <Avatar /> : (
                    <Box>
                      <Button onClick={() => onNavigate('/log-in')} sx={{ mr: 1 }}>Login</Button>
                      <Button onClick={() => onNavigate('/sign-up')} sx={{ ml: 1 }} variant="outlined">
                      Signup
                      </Button>
                    </Box>
                  )}
              </Box>
            )}
          </Toolbar>
        </AppBar>
      </Box>
      {
        user?.userId ? (
        <Box sx={{ display: { xs: 'flex', md: 'none' } }} alignItems="flex-end" justifyContent="space-between" width="100%" px={3} pb={2}>
          <Avatar />
          <Box>
            <LogoutButton color="black" />
          </Box>
        </Box>
        )
          : null
      }
    </>
  );
};

export default Navbar;
