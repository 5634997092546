import React from 'react';
import StyledFulfillmentdPage from './FulfillmentPage.styles';

/* eslint-disable */ 
const FulfillmentPage = () => {
  return (
    <StyledFulfillmentdPage>

    
<div className='WordSection1'>

<p className='MsoNormal'><b>Fulfillment Policy</b></p>

<p className='MsoNormal'>At Easy Button Skip Trace, we strive to provide exceptional
skip tracing services to our customers. Our fulfillment policy outlines our
procedures regarding refunds and cancellations to ensure a transparent and
satisfactory experience for all users.</p>

<p className='MsoNormal'><b>Refunds Policy:</b></p>

<p className='MsoListParagraphCxSpFirst' style={{textIndent:'-.25in'}}><b>1.<span
style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></b><b>Refund
Eligibility:</b></p>

<p className='MsoListParagraphCxSpMiddle' style={{marginLeft:'.75in',textIndent:'-.25in'}}><span
style={{fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Refunds may be eligible under the following circumstances:</p>

<p className='MsoListParagraphCxSpMiddle' style={{marginLeft:'1.25in',textIndent:'-.25in'}}><span
style={{fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;
</span></span>Service failure: In the event that we fail to provide the
requested skip tracing service accurately or within the specified timeframe.</p>

<p className='MsoListParagraphCxSpMiddle' style={{marginLeft:'1.25in',textIndent:'-.25in'}}><span
style={{fontFamily:"Courier New"}}>o<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;
</span></span>Duplicate payment: If a customer unintentionally makes duplicate
payments for the same service.</p>

<p className='MsoListParagraphCxSpMiddle' style={{marginLeft:'.75in',textIndent:'-.25in'}}><span
style={{fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Refunds are subject to verification by our customer support team.</p>

<p className='MsoListParagraphCxSpMiddle' style={{textIndent:'-.25in'}}><b>2.<span
style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></b><b>Refund
Process:</b></p>

<p className='MsoListParagraphCxSpMiddle' style={{marginLeft:'.75in',textIndent:'-.25in'}}><span
style={{fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Customers must contact our customer support team within 30 days
of the service delivery date to request a refund.</p>

<p className='MsoListParagraphCxSpMiddle' style={{marginLeft:'.75in',textIndent:'-.25in'}}><span
style={{fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Refund requests must be accompanied by relevant details such as
the order number and a description of the issue.</p>

<p className='MsoListParagraphCxSpMiddle' style={{marginLeft:'.75in',textIndent:'-.25in'}}><span
style={{fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Once approved, refunds will be processed within 7-10 business
days via the original method of payment.</p>

<p className='MsoListParagraphCxSpMiddle' style={{textIndent:'-.25in'}}><b>3.<span
style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></b><b>Non-Refundable
Services:</b></p>

<p className='MsoListParagraphCxSpMiddle' style={{marginLeft:'.75in',textIndent:'-.25in'}}><span
style={{fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Services rendered as per the customer's request and within the
agreed-upon specifications are generally non-refundable.</p>

<p className='MsoListParagraphCxSpLast' style={{marginLeft:'.75in',textIndent:'-.25in'}}><span
style={{fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>No refunds will be provided for services completed successfully
and as per the customer's instructions.</p>

<p className='MsoNormal'><b>Cancellation Policy:</b></p>

<p className='MsoListParagraphCxSpFirst' style={{textIndent:'-.25in'}}><b>1.<span
style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></b><b>Cancellation
Requests:</b></p>

<p className='MsoListParagraphCxSpMiddle' style={{marginLeft:'.75in',textIndent:'-.25in'}}><span
style={{fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Customers may request cancellations of their orders before the
commencement of the skip tracing process.</p>

<p className='MsoListParagraphCxSpMiddle' style={{marginLeft:'.75in',textIndent:'-.25in'}}><span
style={{fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Cancellation requests must be made through our customer support email
and include relevant order details. <a
href="mailto:support@easybuttonskiptrace.com">support@easybuttonskiptrace.com</a></p>

<p className='MsoListParagraphCxSpMiddle' style={{textIndent:'-.25in'}}><b>2.<span
style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></b><b>Cancellation
Fees:</b></p>

<p className='MsoListParagraphCxSpMiddle' style={{marginLeft:'.75in',textIndent:'-.25in'}}><span
style={{fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Cancellations made before the initiation of the skip tracing
process will not incur any cancellation fees.</p>

<p className='MsoListParagraphCxSpMiddle' style={{marginLeft:'.75in',textIndent:'-.25in'}}><span
style={{fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Cancellations requested after the commencement of the service may
be subject to a cancellation fee, determined on a case-by-case basis.</p>

<p className='MsoListParagraphCxSpMiddle' style={{textIndent:'-.25in'}}><b>3.<span
style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></b><b>Cancellation
Process:</b></p>

<p className='MsoListParagraphCxSpMiddle' style={{marginLeft:'.75in',textIndent:'-.25in'}}><span
style={{fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Upon receiving a cancellation request, our team will review the
status of the order and process the cancellation accordingly.</p>

<p className='MsoListParagraphCxSpLast' style={{marginLeft:'.75in',textIndent:'-.25in'}}><span
style={{fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>If applicable, any eligible refunds will be processed as per our
refunds policy.</p>

<p className='MsoNormal'><b>Additional Notes:</b></p>

<p className='MsoListParagraphCxSpFirst' style={{textIndent:'-.25in'}}><span
style={{fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b>Communication:</b> We encourage open communication between our
customers and our support team to address any concerns or issues promptly.</p>

<p className='MsoListParagraphCxSpLast' style={{textIndent:'-.25in'}}><span
style={{fontFamily:'Symbol'}}>·<span style={{font:'7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b>Service Modifications:</b> We reserve the right to modify or
update our fulfillment policy at any time. Any changes will be communicated to
our customers via email or through our website.</p>

<p className='MsoNormal'>By utilizing our skip tracing services, customers agree to
adhere to the terms outlined in this fulfillment policy.</p>

<p className='MsoNormal'>For further assistance or inquiries regarding our
fulfillment policy, please contact our customer support team at <a
href="mailto:support@easybuttonskiptrace.com">support@easybuttonskiptrace.com</a></p>

<p className='MsoNormal'>&nbsp;</p>

</div>



    </StyledFulfillmentdPage>

  );
};

export default FulfillmentPage;
