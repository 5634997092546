import { Card, Typography, Box, Button, Chip } from '@mui/material';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/check-icon.svg';
import config from '../../../../config';

type PropsType = {
  id?: string | null;
  name: string;
  price: string;
  description?: string;
  features: string[];
  onClick: (x: string) => void;
  status?: string;
  manageSubscription: boolean;
  disabled: boolean;
};

const SubscriptionCard: React.FC<PropsType> = (props) => {
  const handleManageSubscription = () => {
    const link = document.createElement('a');
    link.href = config.stripeCustomerPortalUrl || '';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleOnClick = () => {
    if (!props.id) {
      return;
    }
    props.onClick(props.id);
  };

  return (
    <Card sx={{ py: 6, px: 4, borderRadius: 3, width: '100%', maxWidth: '380px', display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Box>
        {props.status && <Chip sx={{ float: 'right', textTransform: 'capitalize', color: 'primary.main' }} label={props.status} />}
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: 900,
            textTransform: 'uppercase',
          }}
        >
          {props.name}
        </Typography>
        <Typography sx={{ mt: 1, fontSize: '15px', fontWeight: 300 }}>{props.description}</Typography>
      </Box>
      <Box
        sx={{ px: 2, py: 1.5, bgcolor: 'secondary.light', display: 'flex', justifyContent: 'space-between', borderRadius: 2 }}
        >
        <Typography sx={{ fontSize: '15px', fontWeight: 300 }}>Price</Typography>
        <Typography sx={{ fontSize: '15px', fontWeight: 700, color: 'primary.main' }}>$ {props.price}</Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {
          props.features.map((item, index) => (
            <Box key={index} sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <CheckIcon />
              <Typography>{item}</Typography>
            </Box>
          ))
        }
      </Box>
      <Box>
        {props.id !== null && props.manageSubscription ? (
          <Button fullWidth sx={{ py: 1.5, borderRadius: 2 }} variant="contained" onClick={handleManageSubscription}>
            Manage Subscription
          </Button>
        ) : (
          <Button
            fullWidth
            sx={{ py: 1.5, borderRadius: 2 }}
            variant="contained"
            onClick={props.disabled ? undefined : handleOnClick}
            disabled={props.disabled}
          >
            Subscribe
          </Button>
        )}
      </Box>
    </Card>
  );
};

export default SubscriptionCard;
