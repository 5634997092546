import styled from 'styled-components';

export default styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .styled-bill__container {
    width: 100%;
    padding: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 48px;
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 1px solid ${({ theme }) => theme.mainTheme.color.grey.background};

    &__title {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      row-gap: 16px;

      h3 {
        color: ${({ theme }) => theme.mainTheme.color.typography.blackText};
      }

      .text-2 {
        color: ${({ theme }) => theme.mainTheme.color.typography.greyText};
      }
    }

    &__main-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      &__section {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 46px;
        border-bottom: 1px solid ${({ theme }) => theme.mainTheme.color.grey.background};
      }

      &__total-price {
        color: ${({ theme }) => theme.mainTheme.color.red.primary};
      }
    }
  }

`;
