import React, { useMemo } from 'react';
import clsx from 'clsx';
import { StatusCodes } from 'http-status-codes';
import { toast } from 'react-toastify';
import GhostButton from '../GhostButton/GhostButton';
import StyledTableBodyRow from './TableBodyRowOrderAdmin.styles';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg';
// import { ReactComponent as RefreshIcon } from '../../assets/icons/refresh.svg';
import type { DownloadAdminOrderRequestType, OrderType, UploadAdminOrderRequestType } from '../../types/orderTypes';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import orderThunk from '../../store/order/orderThunk';
import { convertFileToBase64 } from '../../utils/convertFileHelper';
import { orderAPI } from '../../api/services/orderApi';
import { formattingDate } from '../../utils/formattingDateHelper';
import type { UserType } from '../../types/userTypes';

type PropsType = {
  item: OrderType;
  className?: string;
  user: UserType;
};

const TableBodyRowOrderAdmin: React.FC<PropsType> = (props) => {
  const { item, className = '', user } = props;
  const dispatch = useAppDispatch();
  const filter = useAppSelector((state) => state.orderSlice.filter);
  const currentStatusClassName = (status: string) => `${status.split(' ').join('-').toLowerCase()}-status`;
  const correctDisplayInfo = (info: number | string | null) => (info || '-');

  // const onClickProcess = async (
  //   item: OrderType, e: React.MouseEvent<HTMLDivElement>,
  // ) => {
  //   e.preventDefault();
  //   try {
  //     await orderAPI.processOutputFileSFTP(item.userFileId);
  //     toast.success('Skip Ready successfully scheduled.');
  //     await dispatch(orderThunk.getAdminOrders(filter)).unwrap();
  //   } catch (error) {
  //     toast.error(error);
  //   }
  // };

  const onClickDownloadOuput = async (
    item: OrderType, e: React.MouseEvent<HTMLDivElement>,
  ) => {
    let nameFileAdmin = '';
    e.preventDefault();
    try {
      if (item.nameFileAdmin) {
        nameFileAdmin = item.nameFileAdmin;
        const fileInfo: DownloadAdminOrderRequestType = {
          name: nameFileAdmin,
          userId: item.user.userId,
        };

        const res = await orderAPI.downloadOutputAdminFile(fileInfo);
        if (res.status === StatusCodes.OK) {
          const link = document.createElement('a');
          link.href = res.data.data.file;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        await dispatch(orderThunk.getAdminOrders(filter)).unwrap();
      } else {
        toast.error('Skip Ready file is not available yet.');
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const onClickDownloadInput = async (
    item: OrderType, e: React.MouseEvent<HTMLDivElement>,
  ) => {
    e.preventDefault();
    try {
      const fileInfo: DownloadAdminOrderRequestType = {
        name: item.name,
        userId: item.user.userId,
      };

      const res = await orderAPI.downloadInputAdminFile(fileInfo);
      if (res.status === StatusCodes.OK) {
        const link = document.createElement('a');
        link.href = res.data.data.file;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      await dispatch(orderThunk.getAdminOrders(filter)).unwrap();
    } catch (error) {
      toast.error(error);
    }
  };

  const onClickUpload = async (
    item: OrderType,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    e.preventDefault();
    try {
      if (e.target.files && e.target.files[0]) {
        const currentFile = e.target.files[0];
        if (currentFile.name.endsWith('.csv')) {
          const fileInfo: UploadAdminOrderRequestType = {
            userId: item.user.userId,
            originFileName: item.name,
            fileName: currentFile.name,
            file: '',
          };
          const convertedFile = await convertFileToBase64(currentFile);
          fileInfo.file = convertedFile.split('base64,')[1];
          await dispatch(orderThunk.uploadAdminFile(fileInfo)).unwrap();
          await dispatch(orderThunk.getAdminOrders(filter)).unwrap();
          toast.success('The file has been uploaded');
        }
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const orderValueArray = useMemo(() => {
    let object = { content: (
        <UploadIcon />
    ) };
    if (user.email.toLocaleLowerCase() === 'andreremigiobr@gmail.com') {
      object = {
        content: (
          <div>
            <GhostButton className="secondary">
              <label id="label-file-upload" htmlFor={`input-file-upload-${item.userFileId}`}>
                <input
                  type="file"
                  id={`input-file-upload-${item.userFileId}`}
                  onChange={(e) => onClickUpload(item, e)}
                  accept=".csv"
                />
                <UploadIcon />
              </label>
            </GhostButton>
            {/* <GhostButton className="secondary">
                <div onClick={(e) => onClickProcess(item, e)}>
                  <RefreshIcon />
                </div>
            </GhostButton> */}
          </div>
        ),
      };
    } else {
      object = {
        content: (
          <div>
            <GhostButton className="secondary">
              <label id="label-file-upload" htmlFor={`input-file-upload-${item.userFileId}`}>
                <input
                  type="file"
                  id={`input-file-upload-${item.userFileId}`}
                  onChange={(e) => onClickUpload(item, e)}
                  accept=".csv"
                />
                <UploadIcon />
              </label>
            </GhostButton>
          </div>
        ),
      };
    }
    return [
      { content: item.userFileId },

      {
        content: item.name,
        type: 'p',
      },

      { content: formattingDate(item.createdAt) },

      { content: correctDisplayInfo(item?.user.fullName) },

      { content: item.user.email },

      { content: `$${item.price.toFixed(2)}` },

      {
        content: item.status,
        type: 'div',
        className: clsx(['order-status', currentStatusClassName(item.status)]),
      },

      { content: correctDisplayInfo(item.notes) },

      {
        content: (
          <div>
            <GhostButton>
              <div
                onClick={(e) => onClickDownloadInput(item, e)}
              >
                <DownloadIcon />
                <p className="text-3">Input</p>
              </div>
            </GhostButton>
            <GhostButton>
              <div
                onClick={(e) => onClickDownloadOuput(item, e)}
              >
                <DownloadIcon />
                <p className="text-3">Output</p>
              </div>
            </GhostButton>
          </div>
        ),
      },

      object,
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  return (
    <StyledTableBodyRow className={className}>

      {orderValueArray.map((item, index) => {
        if (item.type === 'p') {
          return (
            <td key={index}>
              <p className="styled-table-row__text">{item.content}</p>
            </td>
          );
        }
        if (item.type === 'div') {
          return (
            <td key={index}>
              <div className={clsx(item.className)}>{item.content}</div>
            </td>
          );
        }
        return (
          <td key={index}>
            <div>
              {item.content}
            </div>
          </td>
        );
      })}
    </StyledTableBodyRow>
  );
};

export default TableBodyRowOrderAdmin;
