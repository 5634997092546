import Papa from 'papaparse';

type DDType = {
  records: number;
  fields: Array<string>;
};

export const parseFile = async (file: File) => {
  const data: DDType = await new Promise((resolve) => {
    const onComplete = (results: Papa.ParseResult<unknown>) => {
      return resolve({ fields: results.data[0] as Array<string>, records: results.data.length });
    };

    Papa.parse(file, {
      complete(results) {
        onComplete(results);
      },
    });
  });

  return data;
};
