/* eslint-disable */ import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import StyledDragAndDropArea from './DragAndDropArea.styles';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow-right.svg';
import { Button } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import ErrorMappingModal from '../ErrorMappingModal/ErrorMappingModal';
import ErrorBalanceModal from '../ErrorBalanceModal/ErrorBalanceModal';
import ErrorCountRecordsModal from '../ErrorCountRecordsModal/ErrorCountRecordsModal';
import FileUpload from '../FileUpload/FileUpload';
import {
  setLoading,
  setUploadFile,
  unsetLoading,
  unsetUploadFile,
  setDiscount,
} from '../../store/order/orderSlice';
import { parseFile } from '../../utils/parsingFileHelper';
import { convertFileToBase64 } from '../../utils/convertFileHelper';
import { getUploadFileName } from '../../utils/getUploadFileName';
import MappingDataArea from '../MappingDataArea/MappingDataArea';

type PropsType = {
  onClick?: () => void;
  className?: string;
} & React.PropsWithChildren;

const DragAndDropArea: React.FC<PropsType> = (props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoading = useAppSelector(
    (state) => state.orderSlice.addition.isLoading
  );
  const userInfo = useAppSelector((state) => state.userSlice.user);
  const currentDiscount = userInfo.discount ? userInfo.discount : 0;
  dispatch(setDiscount({ discount: currentDiscount }));

  const [isFileUpload, setIsFileUpload] = useState(false);
  const [isDragActive, setIsDragActive] = useState(false);
  const [isOpenImportListModal, setIsOpenImportListModal] = useState(false);
  const [fileInfo, setFileInfo] = useState({
    name: '',
    size: 0,
  });
  const [isOpenErrorMappingModal, setIsOpenErrorMappingModal] = useState(false);
  const [isOpenErrorBalanceModal, setIsOpenErrorBalanceModal] = useState(false);
  const [isOpenErrorCountRecordsModal, setIsOpenErrorCountRecordsModal] =
    useState(false);

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const isActiveEvent = e.type === 'dragenter' || e.type === 'dragover';
    if (!isFileUpload) {
      if (isActiveEvent) {
        setIsDragActive(true);
      } else if (e.type === 'dragleave') {
        setIsDragActive(false);
      }
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(false);
    if (!isFileUpload) {
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        if (e.dataTransfer.files[0].name.endsWith('.csv')) {
          onUploadFile(e.dataTransfer.files[0]);
        }
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setIsOpenErrorCountRecordsModal(false);
    setIsOpenErrorBalanceModal(false);
    if (e.target.files && e.target.files[0]) {
      const currentFile = e.target.files[0];
      if (currentFile.name.endsWith('.csv')) {
        onUploadFile(currentFile);
      }
    }
  };

  const onUploadFile = async (file: File) => {
    /* eslint-disable no-var */
    var cost = 0;
    /* eslint-enable no-var */
    try {
      setFileInfo({ name: file.name, size: file.size });
      dispatch(setLoading());
      const { records, fields } = await parseFile(file);
      const convertedFile = await convertFileToBase64(file);
      dispatch(unsetLoading());

      /* eslint-disable no-console */
      // console.log('records', records);
      /* eslint-enable no-console */

      if (
        fields.length === 21 &&
        fields[0].toLowerCase().trim() === 'Owner'.toLowerCase() &&
        fields[1].toLowerCase().trim() === 'FN'.toLowerCase() &&
        fields[2].toLowerCase().trim() === 'LN'.toLowerCase() &&
        fields[3].toLowerCase().trim() === 'OA'.toLowerCase() &&
        fields[4].toLowerCase().trim() === 'OC'.toLowerCase() &&
        fields[5].toLowerCase().trim() === 'OS'.toLowerCase() &&
        fields[6].toLowerCase().trim() === 'OZ'.toLowerCase() &&
        fields[7].toLowerCase().trim() === 'PID'.toLowerCase() &&
        fields[8].toLowerCase().trim() === 'SA'.toLowerCase() &&
        fields[9].toLowerCase().trim() === 'SC'.toLowerCase() &&
        fields[10].toLowerCase().trim() === 'SS'.toLowerCase() &&
        fields[11].toLowerCase().trim() === 'SZ'.toLowerCase() &&
        fields[12].toLowerCase().trim() === 'LSF'.toLowerCase() &&
        fields[13].toLowerCase().trim() === 'BSF'.toLowerCase() &&
        fields[14].toLowerCase().trim() === 'LU'.toLowerCase() &&
        fields[15].toLowerCase().trim() === 'ALV'.toLowerCase() &&
        fields[16].toLowerCase().trim() === 'AIV'.toLowerCase() &&
        fields[17].toLowerCase().trim() === 'AV'.toLowerCase() &&
        fields[18].toLowerCase().trim() === 'AY'.toLowerCase() &&
        fields[19].toLowerCase().trim() === 'MI'.toLowerCase() &&
        fields[20].toLowerCase().trim() === 'ZD'.toLowerCase()
      ) {
        cost = (records - 1) * userInfo.llcCost; // There is no discount for LLC Skip
      } else {
        cost = (records - 1) * userInfo.rowCost * (1 - currentDiscount);
      }

      if (userInfo.balance < cost) {
        setIsOpenErrorBalanceModal(true);
      } else if (
        !(
          fields.length === 10 &&
          fields[0].toLowerCase().trim() === 'firstname' &&
          fields[1].toLowerCase().trim() === 'lastname' &&
          fields[2].toLowerCase().trim() === 'recipientaddress' &&
          fields[3].toLowerCase().trim() === 'recipientcity' &&
          fields[4].toLowerCase().trim() === 'recipientstate' &&
          fields[5].toLowerCase().trim() === 'recipientpostalcode' &&
          fields[6].toLowerCase().trim() === 'propertyaddress' &&
          fields[7].toLowerCase().trim() === 'propertycity' &&
          fields[8].toLowerCase().trim() === 'propertystate' &&
          fields[9].toLowerCase().trim() === 'propertypostalcode'
        ) &&
        !(fields.length === 1 && fields[0].toLowerCase().trim() === 'email') &&
        !(
          fields.length === 2 &&
          fields[0].toLowerCase().trim() === 'id' &&
          fields[1].toLowerCase().trim() === 'phone number'
        ) &&
        !(
          fields.length === 5 &&
          fields[0].toLowerCase().trim() === 'id' &&
          fields[1].toLowerCase().trim() === 'address' &&
          fields[2].toLowerCase().trim() === 'city' &&
          fields[3].toLowerCase().trim() === 'state' &&
          fields[4].toLowerCase().trim() === 'zip'
        ) &&
        !(
          fields.length === 21 &&
          fields[0].toLowerCase().trim() === 'Owner'.toLowerCase() &&
          fields[1].toLowerCase().trim() === 'FN'.toLowerCase() &&
          fields[2].toLowerCase().trim() === 'LN'.toLowerCase() &&
          fields[3].toLowerCase().trim() === 'OA'.toLowerCase() &&
          fields[4].toLowerCase().trim() === 'OC'.toLowerCase() &&
          fields[5].toLowerCase().trim() === 'OS'.toLowerCase() &&
          fields[6].toLowerCase().trim() === 'OZ'.toLowerCase() &&
          fields[7].toLowerCase().trim() === 'PID'.toLowerCase() &&
          fields[8].toLowerCase().trim() === 'SA'.toLowerCase() &&
          fields[9].toLowerCase().trim() === 'SC'.toLowerCase() &&
          fields[10].toLowerCase().trim() === 'SS'.toLowerCase() &&
          fields[11].toLowerCase().trim() === 'SZ'.toLowerCase() &&
          fields[12].toLowerCase().trim() === 'LSF'.toLowerCase() &&
          fields[13].toLowerCase().trim() === 'BSF'.toLowerCase() &&
          fields[14].toLowerCase().trim() === 'LU'.toLowerCase() &&
          fields[15].toLowerCase().trim() === 'ALV'.toLowerCase() &&
          fields[16].toLowerCase().trim() === 'AIV'.toLowerCase() &&
          fields[17].toLowerCase().trim() === 'AV'.toLowerCase() &&
          fields[18].toLowerCase().trim() === 'AY'.toLowerCase() &&
          fields[19].toLowerCase().trim() === 'MI'.toLowerCase() &&
          fields[20].toLowerCase().trim() === 'ZD'.toLowerCase()
        )
      ) {
        setIsOpenErrorMappingModal(true);
      } else {
        dispatch(
          setUploadFile({
            name: getUploadFileName(file.name),
            csvBase64: convertedFile.split('base64,')[1],
            records,
            fields,
          })
        );
        setIsFileUpload(true);
        setIsOpenErrorMappingModal(false);
        setIsOpenErrorCountRecordsModal(false);
        setIsOpenErrorBalanceModal(false);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const deleteFile = async () => {
    try {
      dispatch(unsetUploadFile());
      setIsFileUpload(false);
      setFileInfo({ name: '', size: 0 });
    } catch (error) {
      toast.error(error);
    }
  };

  const onClickMappingData = () => {
    setIsOpenImportListModal(true);
  };
  const handleCloseImportListModal = () => {
    setIsOpenImportListModal(false);
  };

  const onClickCancelBalanceModal = () => {
    setIsFileUpload(false);
    setIsOpenErrorBalanceModal(false);
  };

  const onCurrentClickBalanceModal = () => {
    navigate('/payments/enter-amount');
  };

  const onClickCancelRecordsModal = () => {
    setIsFileUpload(false);
    setIsOpenErrorCountRecordsModal(false);
  };

  const onClickCancelMappingModal = () => {
    setIsFileUpload(false);
    setIsOpenErrorMappingModal(false);
  };

  return (
    <StyledDragAndDropArea
      onClick={props?.onClick}
      className={props?.className ?? ''}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      isDragActive={isDragActive && !isFileUpload}
    >
      <FileUpload
        isLoading={isLoading}
        isFileUpload={isFileUpload}
        fileInfo={fileInfo}
        handleChange={handleChange}
        deleteFile={deleteFile}
      />

      <Button endIcon={<ArrowRightIcon fill="white"/>} variant="contained" sx={{ py: 1.5, borderRadius: 2 }} disabled={!isFileUpload} onClick={onClickMappingData}>
        <h4>Review Order</h4>
      </Button>

      {isOpenImportListModal ? (
        <MappingDataArea
          open={isOpenImportListModal}
          onClose={handleCloseImportListModal}
        />
      ) : null}

      {isOpenErrorMappingModal ? (
        <ErrorMappingModal
          handleChange={handleChange}
          onCloseModal={onClickCancelMappingModal}
        />
      ) : null}

      {isOpenErrorBalanceModal ? (
        <ErrorBalanceModal
          onCloseModal={onClickCancelBalanceModal}
          onClick={onCurrentClickBalanceModal}
        />
      ) : null}

      {isOpenErrorCountRecordsModal ? (
        <ErrorCountRecordsModal
          handleChange={handleChange}
          onCloseModal={onClickCancelRecordsModal}
        />
      ) : null}
    </StyledDragAndDropArea>
  );
};

export default DragAndDropArea;
